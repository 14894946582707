import { Box } from '@mui/material'

const Body = ({children}) => {
  return (
    <Box display="flex" flexDirection="column" sx={{
      // marginTop: {xs: 1, sm: 2},
      gap: 4
    }}>
      {children}
    </Box>
  )
}

export default Body;
