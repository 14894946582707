import Container from '../../comps/Container'
import { SubTitle1, Text1, Text2, Title } from '../../comps/Type'
import Section from '../../comps/Section'
import { Box } from '@mui/material'
import Body from '../../comps/Body'

const Tile = ({color, children}) => {
  return (
    <Box sx={{
      width: '100%',
      // height: '256px',
      color: 'white',
      bgcolor: `${color}.main`,
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      p: 4,
    }}>
      {children}
    </Box>
  )
};

const WhatsNew = () => {
  return (
    <Section id="whatsnew">
      <Container sx={{alignItems: 'flex-start'}}>
        <Title>New in 2024</Title>
        <Body>
          <Text1>This year P4P is simplified, but with the same great beer and prizes, along with a new loop!</Text1>
          <Box sx={{display: 'grid', width: '100%', gap: 2, gridTemplateColumns: {
              xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'
            }}}>
            <Tile color="primary">
              <SubTitle1 sx={{fontWeight:900}}>Highline/Cloudline Loop</SubTitle1>
              <Text2 fontWeight={500}>
                While P4P still has the classic Montane/Horseshoe loop, we're adding some variety this year, with a second loop to ride. Participants
                can now go out and ride the Highline - Cloudline - Loki loop, and still earn a free pint courtesy of Sheepdog Brewing.
              </Text2>
            </Tile>
            <Tile color="secondary">
              <SubTitle1 sx={{fontWeight:900}}>No More QR Codes</SubTitle1>
              <Text2 fontWeight={500}>
                This year, we're ditching the QR codes, in favour of selfies along the way. Participants now just take a selfie at each of the
                landmarks along the loop, and show them to the folks at Sheepdog within 24 hours to claim their free beer.
              </Text2>
            </Tile>
            <Tile color="tertiary">
              <SubTitle1 sx={{fontWeight:900}}>Entry Fee for Prizes</SubTitle1>
              <Text2 fontWeight={500}>
                Last year, we raised funds for local trails through donations to segments. This year, we're simplifying things
                with a flat fee to enter for prizes. A small fee up front allows a participant to earn as many draws as they want. You can think
                of this as a raffle ticket whose chances of winning increase the more you ride.
              </Text2>
            </Tile>
            {/*<Tile color="quaternary">*/}
            {/*  <SubTitle1 sx={{fontWeight:900}}>Entry Fee for Prizes</SubTitle1>*/}
            {/*  <Text2 fontWeight={500}>*/}

            {/*  </Text2>*/}
            {/*</Tile>*/}
          </Box>
        </Body>

      </Container>
    </Section>
  )
}

export default WhatsNew;
