import React from 'react'
import { Box, Stack, SvgIcon} from '@mui/material'
import Container from '../../comps/Container'
import { SubTitle1, SubTitle2, Text1, Text2 } from '../../comps/Type'
import Section from '../../comps/Section'
import { ReactComponent as P4PLogoTall } from '../../assets/logos/p4p-logo-tall.svg'
import { ReactComponent as P4PLogo } from '../../assets/logos/p4p-logo.svg'
import SolomonLogo from '../../assets/logos/solomon.png';
import GogglesocLogo from '../../assets/logos/gogglesoc.png';
import Launcher from '../../comps/Launcher'

const Landing = () => {
  return (
    <Section>
      <Container>
        <Stack alignItems="center" sx={{
          width: '100%',
          flexDirection: {xs: 'column', md: 'row'},
        }}>
          <SvgIcon component={P4PLogoTall} inheritViewBox sx={{
            height: "512px",
            width: "auto",
            display: {xs: 'none', md: 'block'}
          }}/>
          <SvgIcon component={P4PLogo} inheritViewBox sx={{
            height: "auto",
            width: "100%",
            maxWidth: '512px',
            display: {xs: 'block', md: 'none'}
          }}/>
          <Stack gap={2} sx={{flexGrow: 1}}>
            <SubTitle2 color="primary" textTransform="uppercase">September 27</SubTitle2>
            <SubTitle1 variant="h3">Pedal for Pints is a wrap for 2024.</SubTitle1>
            <Text1>
              Come and join us on September 27 at 6 pm at Sheepdog Brewing for the P4P wrap up party.
            </Text1>
            <Box sx={{
              display: 'grid',
              gridTemplateColumns: {xs: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(4, 1fr)'},
              gap: {xs: 1, sm: 2},
            }}>
              <Launcher title="Selfies & Pints" href="#selfies" color="secondary" size="small"/>
              <Launcher title="Strava & Prizes" href="#strava" color="tertiary" size="small"/>
              <Launcher title="New in 2024" href="#whatsnew" color="quaternary" size="small"/>
              <Launcher title="Register Now" href="/signup" size="small" enabled={false}/>

              {/*<Launcher title="Go to the App" href="/app" color="quaternary" size="small"/>*/}
            </Box>
            <Stack direction="row" alignItems="center" justifyContent="flex-end" gap={2}>
              <Text2 sx={{alignSelf: 'flex-end', mr: 4}}>Additional prizing courtesy of</Text2>
              <Box component="img" src={SolomonLogo} sx={{width: '96px'}}/>
              <Box component="img" src={GogglesocLogo} sx={{width: '96px'}}/>
            </Stack>
          </Stack>
        </Stack>
        {/*<Grid container alignItems="center" justifyContent="center" spacing={1}>*/}
        {/*  <Grid item md={6}>*/}

        {/*  </Grid>*/}
        {/*  <Grid item md={6}>*/}
        {/*    <Stack gap={1} sx={{*/}
        {/*      textAlign: {xs: 'center', md: 'left'}, pt: {xs: 4, md: 0}*/}
        {/*    }}>*/}
        {/*      <Typography variant="h3" fontWeight={900} textTransform="uppercase" letterSpacing="0.35rem" lineHeight="1" sx={{*/}
        {/*        fontSize: {xs: "2rem"}*/}
        {/*      }}>Ride, Run & Support Your Local Trails</Typography>*/}
        {/*      <Text1 gutterBottom>A summer full of free pints, sick prizes, and much more.</Text1>*/}
        {/*      <Typography variant="h4" color="primary"  fontWeight={900}>Starting August 1</Typography>*/}
        {/*      /!*<Box display="flex" gap={1} sx={{flexDirection: {xs: 'column', sm:'row'}}}>*!/*/}
        {/*      /!*  <Button component="a" href="#howitworks" variant="contained" size="large" sx={{*!/*/}
        {/*      /!*    fontSize: '1.25rem', fontWeight: 'bold',*!/*/}
        {/*      /!*  }}>*!/*/}
        {/*      /!*    Know Before You Go*!/*/}
        {/*      /!*  </Button>*!/*/}
        {/*      /!*  <Button component="a" href="/app" variant="contained" size="large" color="secondary" sx={{*!/*/}
        {/*      /!*    fontSize: '1.25rem', fontWeight: 'bold',*!/*/}
        {/*      /!*  }}>*!/*/}
        {/*      /!*    Go to the App*!/*/}
        {/*      /!*  </Button>*!/*/}
        {/*      /!*</Box>*!/*/}
        {/*    </Stack>*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
      </Container>
    </Section>
  )
}

export default Landing;
