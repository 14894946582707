import React from 'react'
import { Box, Stack } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';
import Section from '../../comps/Section'
import Container from '../../comps/Container'
import { Text2, Title } from '../../comps/Type'
import Body from '../../comps/Body'
import GroupRide1 from '../../assets/photos/group-ride-2.jpg'
import GroupRide2 from '../../assets/photos/group-ride-1.jpg'
import SheepdogSocial from '../../assets/photos/sheepdog-social-1.jpg'
import SheepdogCamba from '../../assets/photos/sheepdog+camba.jpg'


const Intro = () => {
  return (
    <Section id="intro">
      <Container sx={{alignItems: 'flex-start'}}>
        <Box display="grid" gap={2} sx={{
          gridTemplateColumns: {xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'},
        }}>
          <Stack gap={1}>
            <Title>What is Pedal for Pints?</Title>
            <Body>
              <Text2>
                Pedal for Pints, known also as "P4P", is an annual fundraiser, organized by CAMBA, Sheepdog Brewing, and The Bike Shop to raise money for local trails.
                Participants go out and pedal or run a loop of trails, and then head to Sheepdog Brewing for a free pint of beer. Along the way, participants
                earn prize draws simply by riding segments along the loop. At the end of the event, Sheepdog hosts a party where the prize winners are drawn.
              </Text2>
            </Body>
          </Stack>
          <Swiper modules={[Autoplay, EffectFade]}
                  effect="fade"
                  fadeEffect={{ crossFade: true }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false
                  }}
                  speed={4000}
                  loop={true}
                  slidesPerView={1}
                  style={{width: '100%'}}>
            <SwiperSlide>
              <Box component="img" src={GroupRide1} sx={{
                width:'100%', height: '100%', objectFit: 'cover', objectPosition: 'center'
              }}/>
            </SwiperSlide>
            <SwiperSlide>
              <Box component="img" src={GroupRide2} sx={{
                width:'100%', height: '100%', objectFit: 'cover', objectPosition: 'center'
              }}/>
            </SwiperSlide>
            <SwiperSlide>
              <Box component="img" src={SheepdogSocial} sx={{
                width:'100%', height: '100%', objectFit: 'cover', objectPosition: 'center'
              }}/>
            </SwiperSlide>
            <SwiperSlide>
              <Box component="img" src={SheepdogCamba} sx={{
                width:'100%', height: '100%', objectFit: 'cover', objectPosition: 'center'
              }}/>
            </SwiperSlide>
          </Swiper>
        </Box>
      </Container>
    </Section>
  )
}

export default Intro;
