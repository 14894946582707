import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress, Container,
  Typography,
} from '@mui/material'
import {formatDistance} from 'date-fns';
import {getFunctions, httpsCallable} from 'firebase/functions';
import Spinner from '../../comps/Spinner'
import ErrorAlert from '../../comps/ErrorAlert'
import BeerImage from '../../assets/photos/sheepdog+camba.jpg'


const PintPage = () => {
  const {pint} = useParams();
  const [isLoading, setLoading] = useState(true);
  const [isRedeeming, setRedeeming] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    httpsCallable(getFunctions(), 'getPint')({pint})
      .then(result => {
        if (result.data.status === 'ok') {
          setData(result.data.payload);
        }
        else {
          if (result.data.code === 'not_found') {
            setError(`Sorry, we couldn't find this pint in our system.`)
          }
          else setError(result.data.payload);
        }
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, [pint]);

  const redeem = () => {
    setRedeeming(true);
    httpsCallable(getFunctions(), 'redeemPint')({pint})
      .then(result => {
        if (result.data.status === 'ok') {
          setData({
            ...data,
            redeemed: new Date().getTime()
          });
        }
        else {
          setError('We were unable to process this pint.')
        }
      })
      .catch(setError)
      .finally(() => setRedeeming(false))
  };

  return (
    <Container maxWidth="sm" sx={{
      display: 'flex',
      minHeight: '100vh',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      {isLoading && <Spinner/>}
      {!isLoading && error && <ErrorAlert error={error}/>}
      {!isLoading && !error &&
        <Card sx={{width: '100%'}}>
          <CardMedia
            component="img"
            height="140"
            image={BeerImage}
            alt="Sheepdog Brewing Logo"
            sx={{filter: data.redeemed ? 'grayscale(100%)' : null}}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {data.item}
            </Typography>
            {data.redeemed && <Typography variant="body2" color="text.secondary">
              This coupon was redeemed {formatDistance(new Date(data.redeemed), new Date(), {
              addSuffix: true
            })} and is no longer valid.
            </Typography>}
            {!data.redeemed && <Typography variant="body1" color="text.secondary">
              {data.user} completed the {data.loop} {formatDistance(new Date(data.created), new Date(), {
              addSuffix: true
            })}.
            </Typography>}
          </CardContent>
          {!data.redeemed &&
            <CardActions>
              <Button size="small" variant='outlined' onClick={redeem} disabled={isRedeeming}
                      endIcon={isRedeeming ? <CircularProgress size="1rem"/> : null}>
                Redeem It
              </Button>
            </CardActions>}
        </Card>
      }
    </Container>
  )
}

export default PintPage;
