import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';
import './swiper.css';
import { alpha, SvgIcon } from '@mui/material'
import { Box, Stack, useTheme } from '@mui/material'
import Section from '../../comps/Section'
import Spinner from '../../comps/Spinner'
import Container from '../../comps/Container'
import { SubTitle1, SubTitle2, Text2, Text3, Title } from '../../comps/Type'
import Launcher from '../../comps/Launcher'
import { loadMap } from '../../state'
import {ReactComponent as RightArrow} from '../../assets/icons/right-arrow.svg';

const Photo = ({src}) => {
  return (
    <Box component='img' src={src} sx={{
      width: '100%', height: {xs: '384px', sm:'512px'}, objectFit: 'cover', objectPosition: 'center',
    }} />
  )

}
const Thumb = ({src, isActive, onClick}) => {
  return (
    <Box component="img" src={src} onClick={onClick} sx={{
      width: '100%', height: {xs: '64px', sm:'96px'},
      objectFit: 'cover', objectPosition: 'center',
      filter: isActive ? 'grayscale(0%)' : 'grayscale(100%)',
      cursor: 'pointer',
      '&:hover': {
        filter: 'grayscale(0%)'
      },
    }} />
  )
}

const Loop = ({loop}) => {
  const theme = useTheme();
  const [slide, setSlide] = useState(-1);
  const [swiper, setSwiper] = useState(null);

  const handleClickThumb = (t) => {
    setSlide(t);
  }

  useEffect(() => {
    if (swiper && slide > -1) {
      swiper.slideTo(slide, 750);
    }
  }, [swiper, slide])

  return (
    <Box display="grid" key={loop.id} alignItems="center" sx={{
      gridTemplateColumns: {xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}, gap: 2,
    }}>
      <Swiper modules={[Autoplay, EffectFade]}
              onSwiper={setSwiper}
              effect='fade'
              fadeEffect={{ crossFade: true }}
              autoplay={false}
              speed={2500}
              loop={false}
              slidesPerView={1}
              style={{ width: '100%'}}>
        <SwiperSlide>
          <Box>
            <Photo src={loop.thumbnail} />
          </Box>
        </SwiperSlide>
        {loop.checkpoints.filter(c => !!c.photo).map((c, i) => <SwiperSlide key={`${loop.id}_${i}`}>
          <Box sx={{ position: 'relative' }}>
            <Stack sx={{
              width: '100%',
              maxWidth: 'fit-content',
              color: 'white',
              bgcolor: 'primary.main',
              p: 4,
              display: {
                xs: 'flex',
                md: 'none'
              },
            }}>
              <Text3>Selfie Point #{i + 1}</Text3>
              <SubTitle1>{c.name}</SubTitle1>
              <Text2>{c.description}</Text2>
            </Stack>
            {c.photo && <Photo src={c.photo} />}
            <Stack sx={{
              position: 'absolute', zIndex: 10, bottom: 0, left: 0, right: 0,
              maxWidth: 'fit-content',
              width: '100%',
              color: 'white',
              backgroundColor: alpha(theme.palette.primary.main, 0.85),
              p: 4,
              display: {
                xs: 'none',
                md: 'flex'
              },
            }}>
              <Text3>Selfie Point #{i + 1}</Text3>
              <SubTitle1>{c.name}</SubTitle1>
              <Text2>{c.description}</Text2>
            </Stack>
          </Box>
        </SwiperSlide>)}
      </Swiper>
      <Stack gap={2} alignItems='flex-start' sx={{
        order: {xs: -1, md: 1},
      }}>
        <SubTitle1>{loop.name} Loop</SubTitle1>
        <Text2>
          {loop.description}
        </Text2>
        <Launcher href={`/map?loop=${loop.id}`} title="Go to the Map" size="small" sx={{p:2}}/>
        <Stack direction="row" gap={2} alignItems="center" justifyContent="center">
          <SvgIcon component={RightArrow} inheritViewBox sx={{
            width: "56px",
            height: "auto",
            padding: theme.spacing(1),
            color: 'secondary.main',
            rotate: '90deg',
            // mr: isSmall?0:2
          }}/>
          <SubTitle2 sx={{flexGrow:1}} fontStyle="italic" color="secondary">
            Click through the photos to view the selfie points.
          </SubTitle2>
        </Stack>
        <Box display="grid" sx={{
          gridTemplateColumns: {xs: 'repeat(6, 1fr)'}, gap: 1,
        }}>
          <Thumb src={loop.thumbnail} isActive={slide === 0} onClick={() => handleClickThumb(0)}/>
          {loop.checkpoints.filter(c => !!c.photo).map((c, t) =>
            <Thumb key={`${loop.id}_thumb_${t}`} src={c.photo} isActive={slide === (t+1)} onClick={() => handleClickThumb(t+1)}/>)}
        </Box>
      </Stack>
    </Box>
  )
}
const Loops = () => {
  const dispatch = useDispatch();
  const loops = useSelector(state => state.map?.loops || []);

  useEffect(() => {
    dispatch(loadMap());
    // eslint-disable-next-line
  }, [])

  return (
    <Section id="loops">
      <Container sx={{alignItems: 'center', gap: {xs: 4, sm: 4, md:8}}}>
        <Title textAlign="center">The Loops</Title>
        {loops.length === 0 && <Spinner/>}
        {loops.map((loop, l) => <Loop loop={loop} key={l}/> )}

        {/*<Box display="grid" sx={{*/}
        {/*  gridTemplateColumns: {xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)'}, gap: 2*/}
        {/*}}>*/}
        {/*  <Stack gap={2} alignItems="flex-start">*/}
        {/*    <SubTitle1>Highline / Cloudline / Loki Loop</SubTitle1>*/}
        {/*    <Text2>*/}
        {/*      The newest Pedal for Pints loop, starting at Quarry Lake parking lot, going up Highline for a full west to east traverse, down the Highline Three Sisters Connector, over to Cloudline, connecting to Loki, and finally Fun Forest back to Quarry Lake.*/}
        {/*    </Text2>*/}
        {/*    <Launcher href="/map?loop=highline-cloudline" title="Go to the Map"/>*/}
        {/*  </Stack>*/}
        {/*  <Box>*/}
        {/*    <Box component="img" src={HighlineCloudlineLoop} sx={{width: '100%', height: 'auto'}}>*/}
        {/*    </Box>*/}
        {/*  </Box>*/}
        {/*</Box>*/}
      </Container>
    </Section>
  )
}

export default Loops;
