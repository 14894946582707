import React from 'react';
import {Box, CircularProgress} from '@mui/material';

const Spinner = ({centered, ...props}) => {
    return (
        <Box sx={centered ? {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)'
        }: {}} {...props}>
            <CircularProgress size="6rem" />
        </Box>
    )
}

export default Spinner;


