import {Alert, AlertTitle, Box} from '@mui/material';

const ErrorAlert = ({error, title, ...props}) => {
  const msg = typeof error === 'string' ? error :
    'message' in error ? error.message : JSON.stringify(error, null, 2);
  const severity = (typeof error === 'object' ? error.severity : null) || 'error';
  const t = (title ? title : (typeof error === 'object' ? error.title : null)) || `Oops, an error occurred.`
  return (
    <Alert severity={severity} {...props}>
      <AlertTitle>{t}</AlertTitle>
      <Box>
        {msg}
      </Box>
    </Alert>
  )
}

export default ErrorAlert;
