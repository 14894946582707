import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Element} from 'react-scroll';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css'
import {parse} from 'wellknown';
import bbox from '@turf/bbox';
import buffer from '@turf/buffer'
import {
  Box,
  Card,
  CardActionArea,
  CardHeader,
  Grid,
  IconButton, Skeleton, Stack,
  SvgIcon,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { loadMap } from '../../state'
import { SubTitle2, Text2, Text3, Title } from '../../comps/Type'
import {ReactComponent as Logo} from '../../assets/logos/p4p-logo.svg';
import StravaLogo from '../../assets/logos/strava.png';
import TrailforksLogo from '../../assets/logos/trailforks.png';
import './popup.css'
import { useSearchParams } from 'react-router-dom'


const Map = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const mapData = useSelector(state => state.map);
  const [params] = useSearchParams();
  const ref = useRef();
  const [map, setMap] = useState();
  const [loop, setLoop] = useState();

  const loopFilter = l => ["==", ["get", "id"], l];
  const segmentFilter = l => ["in", l, ["get", "loops"]];
  const checkpointFilter = l => ["==", ["get", "loop"], l];

  const chooseLoop = l => {
    setLoop(l);
  }

  useEffect(() => {
    mapboxgl.accessToken = 'pk.eyJ1IjoiY2FtYmEiLCJhIjoiY2tqYzcycWVpMHJncDJxbDM0ZXN0eW9lcyJ9._Zytz_BSgXlK3viYAQN12g';

    dispatch(loadMap());
  }, [dispatch]);

  useEffect(() => {
    if (ref.current && mapData) {
      const p = params.get('loop');
      const l = p ? mapData.loops.find(it => it.id === p) : mapData.loops[0];
      setLoop(l);

      const map = new mapboxgl.Map({
        container: ref.current,
        // style: 'mapbox://styles/camba/ckv1pdclo2mcp14s0lw2fo7n3'
        style: 'mapbox://styles/mapbox/outdoors-v11',
        center: [-115.34, 51.09],
        zoom: 14
      });
      map.addControl(new mapboxgl.FullscreenControl());

      const hoverPopup = new mapboxgl.Popup({
        closeButton: true,
        // closeOnClick: false,
        maxWidth: '512px'
      });

      const renderPopup = f => {
        let html = `
          <div class="p4p-popup">
            <h2 class='prize-title'>${f.properties.name}</h2>
            <p>${f.properties.description || ''}</p>
        `;
        if (f.properties.photo) {
          html += `
              <a href='${f.properties.photo}' target='_blank' rel='noreferrer noopener'>Open Photo</a>
              <img src='${f.properties.photo}' alt='${f.properties.name}' style='width: 100%; height: auto;'/>
          `;
        }

        html += `
          </div>
        `;
        return html;
        // const props = f.properties;
        // const s = JSON.parse(props.sponsor || '{}');
        // const p = JSON.parse(props.prize || '{}');
        // const logo = s.logo ?
        //   `<img src='${s.logo}' />` : `<span class='sponsor-name'>${s.name}</span>`;
        // const subtitle = props.subtitle ?
        //     `<p class='subtitle'>${props.subtitle}</p>` : '';
        // const prize = props.type === 'prize' ?
        //   `<div class='prize'>
        //      <p>${props.num_prizes}/${props.max_prizes} ${pluralize(p.short_name, 2)} Claimed</p>
        //      <div class='prize-progress-outer'>
        //         <div class='prize-progress' style='height: 24px; width: ${props.num_prizes / f.properties.max_prizes * 100}%; background-color: ${theme.palette.secondary.main}'></div>
        //      </div>
        //    </div>` : '';
        // return `
        //     <div class="p4p-popup">
        //         ${logo}
        //         <h2 class='title'>${props.name}</h2>
        //         ${subtitle}
        //         <hr/>
        //         ${prize}
        //         <p class='points'>
        //           ${props.points}
        //         </p>
        //     </div>
        // `;
      }

      const initMap = () => {
        map.addSource('loops', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: mapData.loops.filter(l => !!l.wkt).map(l => ({
              type: 'Feature',
              geometry: parse(l.wkt),
              properties: {
                ...l
              }
            }))
          }
        });
        map.addSource('segments', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: mapData.segments.filter(s => !!s.wkt).map(s => ({
              type: 'Feature',
              geometry: parse(s.wkt),
              properties: {
                ...s
              }
            }))
          },
          generateId: true
        });
        map.addSource('checkpoints', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: mapData.loops.map(l => l.checkpoints.map(p => ({
              type: 'Feature',
              geometry: parse(p.geo),
              properties: {
                ...p,
                loop: l.id
              }
            }))).flat()
          }
        })
        map.addSource('segment-buffers', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: mapData.segments.filter(s => !!s.wkt).map(s => ({
              type: 'Feature',
              geometry: buffer(parse(s.wkt), 0.1).geometry,
              // geometry: parse(s.wkt),
              properties: {
                ...s,
                x: buffer(parse(s.wkt), 0.01),
              }
            }))
          },
          generateId: true
        });

        map.addLayer({
          id: 'loops/case',
          type: 'line',
          source: 'loops',
          minzoom: 10,
          layout: {
            'line-join': 'round',
            'line-cap': 'round'
          },
          paint: {
            // 'line-color': '#ccaa00',
            'line-color': '#ffffff',
            'line-width': [
              'interpolate',
              ['exponential', 1.5],
              ['zoom'],
              15,
              10,
              18,
              15
            ]
          }
        })
        map.addLayer({
          id: 'loops',
          type: 'line',
          minzoom: 10,
          source: 'loops',
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            // "line-color": red["600"],
            // "line-pattern": [],
            'line-color': '#4d8c40',
            'line-width': [
              'interpolate',
              ['exponential', 1.5],
              ['zoom'],
              15,
              5,
              18,
              10
            ]

            // "line-blur": 1,
          }
        });
        map.addLayer({
          id: 'segments',
          type: 'line',
          minzoom: 10,
          source: 'segments',
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
          paint: {
            // "line-color": red["600"],
            // "line-pattern": [],
            'line-color': theme.palette.secondary.main,
            'line-width': [
              'interpolate',
              ['exponential', 1.5],
              ['zoom'],
              15,
              5,
              18,
              10
            ]

            // "line-blur": 1,
          }
        });
        map.addLayer({
          id: "segment-buffers",
          type: "fill",
          source: "segment-buffers",
          // "layout": {},
          paint: {
            "fill-color": '#ffffff',
            "fill-opacity": 0,
          }
        });
        map.addLayer({
          id: "checkpoints",
          type: "circle",
          source: "checkpoints",
          paint: {
            "circle-radius": [
              'interpolate',
              ['exponential', 1.5],
              ['zoom'],
              15,
              5,
              20,
              20
            ],
            "circle-color": theme.palette.quaternary.main,
            "circle-stroke-color": "#ffffff",
            "circle-stroke-width": 2
          }
        });
        setMap(map);
      };
      map.on('load', initMap);

      let fid = null;
      map.on("click", "checkpoints", evt => {
        if (evt.features.length > 0) {
          const f = evt.features[0];
          map.getCanvas().style.cursor = 'pointer';
          fid = f.id;

          map.removeFeatureState({source: 'checkpoints'});
          map.setFeatureState(
            { source: 'checkpoints', id: fid },
            { hover: true }
          );

          hoverPopup.setLngLat(evt.lngLat)
            .setHTML(renderPopup(f))
            .addTo(map);
        }
      });
      // map.on("mouseleave", "checkpoints", () => {
      //   map.getCanvas().style.cursor = '';
      //   if (fid !== null) {
      //     map.setFeatureState(
      //       { source: 'checkpoints', id: fid },
      //       { hover: false }
      //     );
      //   }
      //   fid = null;
      //   hoverPopup.remove();
      // });

      // map.on("click", "segment-buffers", evt => {
      //     if (evt.features.length > 0) {
      //         const f = evt.features[0];
      //         map.getCanvas().style.cursor = 'pointer';
      //         fid = f.id;
      //
      //         map.removeFeatureState({source: 'segments'});
      //         map.setFeatureState(
      //             { source: 'segments', id: fid },
      //             { hover: true }
      //         );
      //
      //         hoverPopup.setLngLat(evt.lngLat)
      //             .setHTML(renderPopup(f))
      //             .addTo(map);
      //     }
      // });
      // map.on("mouseleave", "segment-buffers", () => {
      //     map.getCanvas().style.cursor = '';
      //     if (fid !== null) {
      //         map.setFeatureState(
      //             { source: 'segments', id: fid },
      //             { hover: false }
      //         );
      //     }
      //     fid = null;
      //     hoverPopup.remove();
      // });

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, mapData, params]);

  useEffect(() => {
    // console.log('map = ', map);
    // console.log('loop = ', loop);
    if (map && loop) {
      const l = mapData.loops.find(it => it.id === loop?.id);
      if (l) {
        map.setFilter('loops', loopFilter(loop.id));
        map.setFilter('loops/case', loopFilter(loop.id));
        map.setFilter("segments", segmentFilter(loop.id));
        map.setFilter("segment-buffers", segmentFilter(loop.id));
        map.setFilter("checkpoints", checkpointFilter(loop.id));

        // map.setFilter("loop-arrows", loopFilter(loop));
        // map.setFilter("segment-labels", segmentFilter(loop));
        // map.setFilter("segment-points", segmentFilter(loop));
        // map.setFilter("segment-centroid-labels", segmentFilter(loop));
        // map.setFilter("ways", segmentFilter(loop));
        // map.setFilter("stamps", segmentFilter(loop) );
        if (l.wkt) {
          map.fitBounds(bbox(parse(l.wkt)), {
            padding: 100
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loop, map]);

  return (
    <Box>
      <Grid container alignItems="center">
        <Grid item md={6}>
          <Box display="flex" alignItems="center">
            <a href="/">
              <SvgIcon component={Logo} inheritViewBox sx={{
                width: "75px",
                height: "auto",
                padding: theme.spacing(1),
                // mr: isSmall?0:2
              }}/>
            </a>
            <Box pr={2}>
              <Title sx={{whiteSpace: 'nowrap'}}>P4P Loops</Title>
              {/*<Typography variant="body1">Use the buttons to navigate the different P4P loops. Hover on a segment to get more info.</Typography>*/}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          {mapData && <Grid container spacing={1}>
            {mapData.loops.map(l => {
              const isSelected = l.id === loop?.id;
              return (
                <Grid xs={6} item key={l.name}>
                  <Card sx={{
                    backgroundColor: isSelected ? 'white' : 'grey.100',
                    border: '2px solid',
                    borderColor: isSelected ? 'primary.main' : 'text.disabled',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                  }}>
                    <CardActionArea onClick={() => chooseLoop(l)} sx={{flexGrow: 1}}>
                      <CardHeader title={l.name} titleTypographyProps={{
                        variant: 'h6',
                        sx: {lineHeight: 1.2}
                      }}/>
                    {/*  {!isSmall && <CardContent sx={{*/}
                    {/*    pt: 0, display: 'flex', justifyContent: 'space-between',*/}
                    {/*    color: isSelected?'text.secondary':'text.disabled'*/}
                    {/*  }}>*/}
                    {/*    <Box>*/}
                    {/*      <Box display="flex" alignItems="center">*/}
                    {/*        <Typography variant="h5">{l.distance}</Typography>*/}
                    {/*        <Typography variant="subtitle2" sx={{pl:0.5}}>km</Typography>*/}
                    {/*      </Box>*/}
                    {/*      <Typography variant="subtitle2">Distance</Typography>*/}
                    {/*    </Box>*/}
                    {/*    <Box>*/}
                    {/*      <Box display="flex" alignItems="center">*/}
                    {/*        <Typography variant="h5">{l.elevation}</Typography>*/}
                    {/*        <Typography variant="subtitle2" sx={{pl:0.5}}>m</Typography>*/}
                    {/*      </Box>*/}
                    {/*      <Typography variant="subtitle2">Elevation</Typography>*/}
                    {/*    </Box>*/}
                    {/*  </CardContent>}*/}
                    </CardActionArea>
                    {/*<CardActions sx={{*/}
                    {/*  justifyContent: 'flex-end',*/}
                    {/*  backgroundColor: 'grey.100'*/}
                    {/*}}>*/}
                    {/*  {l.trailforks &&*/}
                    {/*    <IconButton href={l.trailforks} target="_blank" rel="noreferrer noopener">*/}
                    {/*      <Box component="img" src={TrailforksLogo} sx={{*/}
                    {/*        width: 32, height: 32, borderRadius: '100%',*/}
                    {/*        filter: isSelected ? 'none' : 'grayscale(100%)'*/}
                    {/*      }}/>*/}
                    {/*    </IconButton>}*/}
                    {/*  {l.strava && <IconButton href={l.strava} target="_blank" rel="noreferrer noopener" sx={{padding: 0}}>*/}
                    {/*    <Box component="img" src={StravaLogo} sx={{*/}
                    {/*      width: 32, height: 32, borderRadius: '100%',*/}
                    {/*      filter: isSelected ? 'none' : 'grayscale(100%)'*/}
                    {/*    }}/>*/}
                    {/*  </IconButton>}*/}
                    {/*</CardActions>*/}
                  </Card>
                </Grid>
              )
            })}
          </Grid>}
        </Grid>
      </Grid>
      <Grid container sx={{
        flexDirection: {xs: 'column-reverse', md: 'row'},
      }}>
        <Grid item xs={12} md={8}>
          <Element name="map">
            <Box ref={ref} mt={1} sx={{width: '100%', height: isSmall ? '90vh' : '728px', maxHeight: '100vh'}}>
              {!map &&  <Skeleton variant="rectangular" sx={{width: '100%', height: '100%'}} />}
            </Box>
          </Element>
        </Grid>
        <Grid item xs={12} md={4}>
          {loop && <Stack p={1} direction="column" gap={2} mt={2} justifyContent="flex-start">
            <SubTitle2 color="primary">The {loop.name} Loop</SubTitle2>
            <Box>
              <Stack direction="row" gap={2} alignItems="center">
                <TrendingFlatIcon/>
                <Text3>Distance</Text3>
                <Box sx={{flexGrow: 1}}></Box>
                <Text3><b>{loop.distance} km</b></Text3>
              </Stack>
              <Stack direction="row" gap={2} alignItems="center">
                <TrendingUpIcon/>
                <Text3>Elevation</Text3>
                <Box sx={{flexGrow: 1}}></Box>
                <Text3><b>{loop.elevation} m</b></Text3>
              </Stack>
            </Box>
            <Text2>{loop.description}</Text2>
            <Stack direction="row">
              {loop.trailforks &&
                <IconButton href={loop.trailforks} target="_blank" rel="noreferrer noopener" sx={{width:'fit-content'}}>
                  <Box component="img" src={TrailforksLogo} sx={{
                    width: 32, height: 32, borderRadius: '100%',
                  }}/>
                </IconButton>}
                {loop.strava && <IconButton href={loop.strava} target="_blank" rel="noreferrer noopener" sx={{
                  width: 'fit-content'
                }}>
                  <Box component="img" src={StravaLogo} sx={{
                    width: 32, height: 32, borderRadius: '100%'
                  }}/>
                </IconButton>}
            </Stack>

          </Stack>}
        </Grid>
      </Grid>
    </Box>

  )
}

export default Map;
