import {Container as MUIContainer} from '@mui/material';

const Container = ({children, fullScreen, sx, ...props}) => {
    return (
        <MUIContainer sx={{
            paddingLeft: '8px !important',
            paddingRight: '8px !important',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            ...sx
        }} {...props}>
            {children}
        </MUIContainer>
    )
}

export default Container;
