import { Box, darken, Typography, useTheme } from '@mui/material'

const Launcher = ({color='primary', title, href, size="normal", external=false, enabled=true, Icon, sx }) => {
  const theme = useTheme();
  const fontSize = size === 'small' ? {xs: '1.15em', sm:'1.25rem'} : {xs: '1.5rem', sm:'2rem'};
  const props = external === true ? {target: '_blank', rel: 'noreferrer noopener'} : {}
  return <Box component={enabled?'a':'span'} href={href} display="flex" alignItems="center" justifyContent="center" sx={{
      bgcolor: enabled?`${color}.main`:'grey.700',
      gap: 2,
      p:{xs: 2, sm:4},
      textDecoration: 'none',
      '&:hover': {
        bgcolor: enabled?darken(theme.palette[color].main, 0.25) : 'grey.700',
        textDecoration: 'none'
      },
      ...sx
    }} {...props}>
    {Icon && <Box component="span" sx={{color: `${color}.contrastText`, fill: 'currentColor'}}>
      <Icon style={{height: '8rem', width: 'auto'}}/>
    </Box>}
    <Typography variant="h4" fontWeight="900" textTransform="uppercase" textAlign="center" sx={{
      color: `${color}.contrastText`,
      fontSize
    }}>{title}</Typography>
  </Box>
}

export default Launcher;
