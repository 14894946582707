import React, {useEffect, useState} from 'react';
import {animateScroll as scroll} from 'react-scroll';
import { Fab, useTheme } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const Floater = () => {
  const theme = useTheme();
  const [show, setShow] = useState(true);

  useEffect(() => {
    const onScroll = () => setShow(window.scrollY > 0);
    onScroll();

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const handleClick = () => {
    scroll.scrollToTop({
      duration: 500,
      smooth: true
    });
  }

  return (
    <Fab color="primary" aria-label="Back to Top of Page" onClick={handleClick} size="small"
         sx={{
           opacity: show ? 1 : 0,
           position: "fixed",
           bottom: theme.spacing(4),
           right: theme.spacing(4),
           zIndex: 100,
           transition: "opacity 0.5s ease-in",
           [theme.breakpoints.down("sm")]: {
             bottom: theme.spacing(1),
             right: theme.spacing(1),
           }
        }}>
      <ExpandLessIcon />
    </Fab>
  )
}

export default Floater;
