import React from 'react';
import {Box} from "@mui/material";

const Root = ({children, sx}) => {
    return (
        <Box position="relative" display="flex" flexDirection="column" sx={{
            minHeight: '100vh', gap: {xs: "2rem", sm: "3rem"},
          ...sx
        }}>
            {children}
        </Box>
    )
}

export default Root;
