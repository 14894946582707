import React from 'react';
import {Box, useTheme} from '@mui/material';
import Root from '../../comps/Root';
import Container from '../../comps/Container';
import Map from './Map'
import Floater from '../../comps/Floater'


const MapPage = () => {
  const theme = useTheme();
  return (
    <Root sx={{backgroundColor: 'white'}}>
      <Container>
        <Box width="100%" sx={{
          marginTop: 1,
          marginBottom: 1,
          [theme.breakpoints.up("sm")]: {marginTop: 2},
          [theme.breakpoints.up("md")]: {marginTop: 3},
          [theme.breakpoints.up("lg")]: {marginTop: 4}
        }}>
          <Map/>
        </Box>
      </Container>
      <Floater/>
    </Root>
  )
}

export default MapPage;
