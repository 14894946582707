import { Typography } from '@mui/material'

export const Title = ({children, sx, ...props}) => {
  return (
    <Typography fontWeight={900} color="primary" textTransform="uppercase" {...props} sx={{
      lineHeight: 1,
      fontSize: {xs: '2rem', sm:'3rem'},
      ...sx
    }}>
      {children}
    </Typography>
  )
}

export const SubTitle1 = ({children, variant="h4", sx, ...props}) => {
  return (
    <Typography variant={variant} fontWeight="bold" {...props} sx={{
      ...sx
    }}>{children}</Typography>
  )
}

export const SubTitle2 = ({children, sx, ...props}) => {
  return (
    <Typography variant="h5" fontWeight="bold" {...props} sx={{
      ...sx
    }}>{children}</Typography>
  )
}

export const Text1 = ({children, sx, ...props}) => {
  return (
    <Typography {...props} sx={{
      fontSize: {xs: '1.25rem', sm:'1.5rem'},
      ...sx
    }}>{children}</Typography>
  )
}

export const Text2 = ({children, sx, ...props}) => {
  return (
    <Typography {...props} sx={{
      fontSize: {xs: '1.15rem', sm: '1.25rem'},
      ...sx
    }}>{children}</Typography>
  )
}

export const Text3 = ({children, sx, ...props}) => {
  return (
    <Typography {...props} sx={{
      fontSize: {xs: '1rem', sm: '1.15rem'},
      ...sx
    }}>{children}</Typography>
  )
}

export const Caption = ({children, sx, ...props}) => {
  return (
    <Typography {...props} sx={{
      fontSize: {xs: '0.85rem', sm: '1rem'},
      ...sx
    }}>{children}</Typography>
  )
}

