import React from 'react';
import Root from '../../comps/Root';
import Landing from './Landing'
import WhatsNew from './WhatsNew'
import Intro from './Intro'
import Header from '../../comps/Header'
import { Stack } from '@mui/material'
import Loops from './Loops'
import Selfies from './Selfies'
import Strava from './Strava'

const HomePage = () => {
    return (
        <Root sx={{backgroundColor: 'white'}}>
          <Header/>
          <Stack sx={{
            // py: {xs: 4, sm: 4, md: 8},
            // gap: {xs: 4, sm: 4, md: 8},
            pb: 8, gap: 8
          }}>
            <Landing/>
            <Intro/>
            <Loops/>
            <Selfies/>
            <Strava/>
            <WhatsNew/>
          </Stack>

          {/*<Sponsors/>*/}

          {/**/}
          {/*<HowItWorks/>*/}
          {/*<GettingStarted/>*/}
          {/*<Launchpad/>*/}

          {/*<Donate/>*/}
          {/*<Prizing/>*/}
          {/*<GrandPrize/>*/}
          {/*<Sponsor/>*/}
          {/*<KoB/>*/}
          {/*<Win/>*/}
        </Root>
    )
}

export default HomePage;
