import React from 'react'
import { Box, Stack } from '@mui/material'
import { ReactComponent as CAMBALogo } from '../assets/logos/camba-word-green.svg'
import TBSLogo from '../assets/logos/the-bike-shop.png';
import SheepdogLogo from '../assets/logos/sheepdog.png';
import Container from './Container'

const Header = () => {
  return (
    <Box sx={{
      py: {xs: 2, md: 4},
      px: {xs: 1, md: 2}
    }}>
      <Container sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <Box component="span" sx={{
          width: {xs: '8rem', sm: '12rem', md: '16rem'},
        }}>
          <CAMBALogo />
        </Box>
        <Stack direction="row" sx={{
          gap: {xs: 1, md: 2}
        }}>
          <Box component="img" src={SheepdogLogo} sx={{
            width: {xs: '5rem', sm: '6rem', md: '8rem'},
          }}></Box>
          <Box component="img" src={TBSLogo} sx={{
            width: {xs: '5rem', sm: '6rem', md: '8rem'},
          }}></Box>
        </Stack>
      </Container>
    </Box>
  )
}

export default Header;
