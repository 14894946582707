import {createStore, applyMiddleware, compose} from 'redux';
import thunkMiddleware from 'redux-thunk'
import {createAction, createAsyncThunk, createReducer} from '@reduxjs/toolkit';
import {getFunctions, httpsCallable} from 'firebase/functions';
import { getAuth, signInWithEmailAndPassword} from 'firebase/auth'

export const MEMBERSHIP_VERIFIED = 'verified';
export const MEMBERSHIP_UNVERIFIED = 'unverified';
export const MEMBERSHIP_DECLINED = 'declined';
export const MEMBERSHIP_INACTIVE = 'inactive';

export const login = createAction('login');
export const logout = createAction('logout');
export const clearAdmin = createAction('clearAdmin');

export const signup = createAsyncThunk(
  'signup',
  async (email, {rejectWithValue}) => {
    try {
      const rsp = await httpsCallable(getFunctions(), 'signup')({email});
      if (rsp.data.status === 'ok') {
        return true;
      }
      else {
        return rejectWithValue(rsp.data);
      }
    }
    catch(err) {
      return rejectWithValue(err)
    }
  }
);

export const verifySignup = createAsyncThunk(
  'signup/verify',
  async (token, {rejectWithValue}) => {
    try {
      const rsp = await httpsCallable(getFunctions(), 'verifySignup')({token});
      if (rsp.data.status === 'ok') {
        return rsp.data.payload.email;
      }
      else {
        return rejectWithValue(rsp.data);
      }
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const register = createAsyncThunk(
  'register',
  async (info, {rejectWithValue}) => {
    try {
      const rsp = await httpsCallable(getFunctions(), 'register')(info);
      if (rsp.data.status === 'ok') {
        return rsp.data.payload;
      }
      else {
        return rejectWithValue(rsp.data);
      }
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const signin = createAsyncThunk(
  'signin',
  async ({email, password}, {rejectWithValue}) => {
    try {
      const user = await signInWithEmailAndPassword(getAuth(), email, password);
      console.log(user);
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
)

export const checkout = createAsyncThunk(
  'checkout',
  async (type, {rejectWithValue}) => {
    try {
      const rsp = await httpsCallable(getFunctions(), 'checkout')({
        type
      });
      if (rsp.data.status === 'ok') {
        return rsp.data.payload;
      }
      else {
        return rejectWithValue(rsp.data);
      }
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const verifyCheckout = createAsyncThunk(
  'checkout/verify',
  async (session, {rejectWithValue}) => {
    try {
      const rsp = await httpsCallable(getFunctions(), 'verifyCheckout')({
        session
      });
      if (rsp.data.status === 'ok') {
        return rsp.data.payload;
      }
      else {
        return rejectWithValue(rsp.data);
      }
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const connectStrava = createAsyncThunk(
  'strava/connect',
  async ({code, scope}, {rejectWithValue}) => {
    try {
      const rsp = await httpsCallable(getFunctions(), 'connectStrava')({
        code, scope
      });
      if (rsp.data.status === 'ok') {
        return rsp.data.payload;
      } else {
        return rejectWithValue(rsp.data);
      }
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

// export const setup = createAsyncThunk(
//   'setup',
//   (info, thunk) => {
//     return httpsCallable(getFunctions(), 'setup')({
//       waiver: true,
//       ...info
//     }).then(result => {
//       if (result.data.status === 'ok') {
//         const auth = getAuth();
//         signOut(auth).then(() => {
//           return signInWithCustomToken(auth, result.data.payload)
//             .then(console.info)
//             .catch(console.error)
//         })
//       }
//     });
//   }
// )

export const loadData = createAsyncThunk(
  'data/load',
  (arg, {rejectWithValue}) => {
    return httpsCallable(getFunctions(), 'fetch')({
    }).then(result => {
      if (result.data.status === 'ok') {
        return result.data.payload;
      } else return rejectWithValue(result.data.payload);
    });
  }
)

export const loadBoard = createAsyncThunk(
  'board/load',
  (arg, {rejectWithValue}) => {
    return httpsCallable(getFunctions(), 'board')({
    }).then(result => {
      if (result.data.status === 'ok') {
        return result.data.payload;
      } else return rejectWithValue(result.data.payload);
    });
  }
)

export const loadSegments = createAsyncThunk(
  'segments/load',
  (arg, {rejectWithValue}) => {
    return httpsCallable(getFunctions(), 'segments')({})
      .then(result => {
        if (result.data.status === 'ok') {
          return result.data.payload;
        } else return rejectWithValue(result.data.payload);
      })
  }
)

export const loadMap = createAsyncThunk(
  'map/load',
  (arg, {rejectWithValue}) => {
    return httpsCallable(getFunctions(), 'map')({
    }).then(result => {
      if (result.data.status === 'ok') {
        return result.data.payload;
      } else return rejectWithValue(result.data.payload);
    });
  }
)

export const verifyMembership = createAsyncThunk(
  'verifyMembership',
  (membershipId, {rejectWithValue}) => {
    return httpsCallable(getFunctions(), 'verifyMembership')({
      membershipId
    }).then(result => {
      if (result.data.status) {
        return result.data;
      } else return rejectWithValue(result.data);
    });
  }
)

export const declineMembership = createAsyncThunk(
  'declineMembership',
  (_, {rejectWithValue}) => {
    return httpsCallable(getFunctions(), 'declineMembership')()
      .then(result => {
        if (result.data.status) {
          return result.data;
        } else return rejectWithValue(result.data);
      });
  }
)

export const resetMembership = createAsyncThunk(
  'resetMembership',
  (_, {rejectWithValue}) => {
    return httpsCallable(getFunctions(), 'resetMembership')()
      .then(result => {
        if (result.data.status) {
          return result.data;
        } else return rejectWithValue(result.data);
      });
  }
)

export const exportDraws =  createAsyncThunk(
  'admin/exportDraws',
  (membershipId, {rejectWithValue}) => {
    return httpsCallable(getFunctions(), 'exportDraws')()
      .then(result => {
        if (result.data.status === 'ok') {
          return result.data;
        } else return rejectWithValue(result.data);
      });
  }
)
const reducer = createReducer({
    work: false,
    init: false,
    user: null,
    data: null,
    board: null,
    segments: null,
    map: null,
    admin: {}
}, builder => {
    builder
        .addCase(login, (state, action) => {
            state.user = action.payload;
            state.init = true;
            state.work = false;
        })
        .addCase(logout, (state, action) => {
            state.user = null;
            state.init = true;
        })
        // .addCase(setup.pending, (state, action) => {
        //     state.work = true;
        // })
        .addCase(loadData.fulfilled, (state, action) => {
            state.data = {...action.payload};
        })
        .addCase(loadBoard.fulfilled, (state, action) => {
          state.board = {...action.payload};
        })
        .addCase(loadMap.fulfilled, (state, action) => {
            state.map = {...action.payload};
        })
        .addCase(loadSegments.fulfilled, (state, action) => {
            state.segments = [...action.payload];
        })
        .addCase(verifyMembership.fulfilled, (state, action) => {
          state.user.membership = action.payload.membership;
        })
        .addCase(declineMembership.fulfilled, (state, action) => {
          state.user.membership = action.payload.membership;
        })
        .addCase(resetMembership.fulfilled, (state, action) => {
          state.user.membership = action.payload.membership;
        })
        .addCase(clearAdmin, (state) => {
          state.admin = { };
        })
        .addCase(exportDraws.pending, (state, action) => {
          state.admin.export = null;
        })
        .addCase(exportDraws.fulfilled, (state, action) => {
          state.admin.export = action.payload.export;
        })
        .addCase(verifyCheckout.fulfilled, (state, action) => {
          state.user.payment_id = action.payload.payment_intent;
        })
        .addCase(connectStrava.fulfilled, (state, action) => {
          state.user.strava_id = action.payload.strava_id;
        });
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default createStore(
    reducer,
    composeEnhancers(applyMiddleware(thunkMiddleware))
);


