import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, doc, getDoc, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import {login, logout} from "./state";


const app = initializeApp({
    apiKey: "AIzaSyBtc3nOgoTY-N5RT9ITamEmvBUZsHDLZtA",
    authDomain: "pedal-for-pints.firebaseapp.com",
    projectId: "pedal-for-pints",
    storageBucket: "pedal-for-pints.appspot.com",
    messagingSenderId: "33603807976",
    appId: "1:33603807976:web:381c540da9e561afb6f75d"
});

const init = store => {
    const auth = getAuth(app);
    const firestore = getFirestore(app);

    const useEmulator = true;
    if (useEmulator && window.location.hostname === "localhost") {
        console.debug("Connecting to Firebase emulator");
        connectAuthEmulator(auth, "http://localhost:9101");
        connectFunctionsEmulator(getFunctions(app), "localhost", 9102);
        connectFirestoreEmulator(firestore, 'localhost', 9103);
        connectStorageEmulator(getStorage(app), "localhost", 9106);
    }

    auth.onAuthStateChanged(user => {
        if (user) {
            user.getIdTokenResult().then((token) => {
                if (token.signInProvider === 'password') {
                    getDoc(doc(firestore,'users', token.claims.sub))
                      .then(u => {
                          if (u.exists()) {
                              store.dispatch(login({
                                  ...u.data(),
                                  ...token
                              }));
                          }
                          else {
                              store.dispatch(logout());
                          }
                      })
                      .catch()
                }
                else store.dispatch(logout());
            });
        }
        else {
            store.dispatch(logout({
                init: true
            }));
        }
    });

}
export default init;
